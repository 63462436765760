import { Maybe } from '@wix/wix-code-adt'
import { AppError, reportUserDataFilteringBI } from '../logger'
import appContext from '../../src/viewer-app-module/DataBindingAppContext'
import { AdaptedComponent } from '../inverted-dependencies/components'
import { RecordStoreService } from '../record-store/service'
import { ConnectionConfig, UoUFilterConnectionConfig } from '../types'
import { DatabindingApiContext } from './types'

export const handleUserInputFilterOnChange = (
  component: AdaptedComponent<ConnectionConfig>,
  connectionConfig: UoUFilterConnectionConfig,
  context: DatabindingApiContext,
) => {
  const { errorReporting } = appContext
  const { actions, recordStore } = context
  const fieldName = connectionConfig.userInputFilter.fieldName

  const onChangeHandler = async () => {
    await actions.refresh()

    const total = recordStore().fold(
      () => Maybe.Nothing(),
      (service: RecordStoreService) => service.getDatasetSize().total,
    )

    reportUserDataFilteringBI({
      filteredBy: fieldName,
      numberOfResults: total,
      state: context.getState(),
      componentType: component.type,
      fieldType: context.getFieldType(fieldName).getOrElse(null),
    })
  }

  component.onChange(
    errorReporting(
      onChangeHandler,
      AppError.withMessage('Filter input adapter onChange failed'),
    ),
  )
}
